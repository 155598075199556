<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <tiempo-form
          v-if="dbReady"
          :id="null"
          :idparte-trabajo="cronometroIdparte"
          :geolocalizacion="geolocalizacion"
          :idtecnico="usuarioIdtecnico"
          :idempleado="usuarioIdempleado"
          :es-desplazamiento="cronometroEsDesplazamiento"
          :por-cronometro="true"
          :para="cronometroPara"
          :finicio="cronometroFinicioToDateTime"
          :has-perm-introducir-tiempos-mas-alla-fecha-actual="hasViewPerm(permissions.tiempos.introducirTiemposMasAllaFechaActual)"
          :has-perm-introducir-otros-tecnicos="hasViewPerm(permissions.tiempos.introducirOtrosTecnicos)"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import TiempoForm from '../components/TiempoForm'
import Data from './CronometroDetenerData'
import { get, sync } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { CRONOMETRO } from '@/utils/consts'
import _ from '@/utils/lodash'
import { currentDateTime, toDateTime, diff as diffDate } from '@/utils/date'

export default {
  components: {
    TiempoForm,
  },
  mixins: [formPageMixin],
  data () {
    return {
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    ...sync(
      'cronometro',
      {
        cronometroFinicio: 'finicio',
        cronometroIdparte: 'idparte',
        cronometroLatitud: 'latitud',
        cronometroLongitud: 'longitud',
        cronometroPara: 'para',
        cronometroEsDesplazamiento: 'esDesplazamiento',
      }
    ),
    usuarioIdtecnico: get('usuario/idtecnico'),
    cronometroFinicioToDateTime () {
      return toDateTime(this.cronometroFinicio)
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      this.title = 'Detener el cronómetro'
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion)
      // alert de tiempo trabajado
      const trabajado = (this.$options.filters.humanizeDuration(
        diffDate(currentDateTime(), toDateTime(this.cronometroFinicio))
      ))
      let paraQuienes = ''
      if (this.cronometroPara === CRONOMETRO.para.paraTodos) {
        paraQuienes = 'Llevan'
      } else {
        paraQuienes = 'Llevas'
      }
      this.$alert.showSnackbarInfo(`${paraQuienes} trabajado ${trabajado}`)
      // alert de varios técnicos
      if (this.cronometroPara === CRONOMETRO.para.paraTodos) {
        this.tecnicosAsignados = await Data.selectTecnicosAsignados(this, this.cronometroIdparte)
        const listaTecnicos = _.map(
          this.tecnicosAsignados, 'empleado.nombre_y_apellido'
        ).join(', ')
        this.$alert.showSnackbarWarning(
          `Vas a detener el cronómetro para: ${listaTecnicos}`, { persistent: true },
        )
      }
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        await Data.detenerTrabajoParte(
          this,
          this.formData,
          this.usuarioIdtecnico,
          toDateTime(this.cronometroFinicio),
          this.cronometroLatitud,
          this.cronometroLongitud,
          this.cronometroIdparte,
        )
        this.cronometroIdparte = null
        this.cronometroFinicio = null
        this.cronometroLatitud = null
        this.cronometroLongitud = null
        this.cronometroPara = null
        this.cronometroEsDesplazamiento = false
        this.$appRouter.go(-1)
      }
    },
  }
}
</script>
